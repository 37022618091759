import React from 'react';
import { Box } from '@mui/material';

function Spacer() {
    return (
        <Box sx={{ flexGrow: 1 }} />
    );
}

export default Spacer;
