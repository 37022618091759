import React, { useState } from 'react';
import { Box, FormControlLabel, Checkbox, Button } from '@mui/material';
import { toast,  } from 'react-toastify';
import {MuiTelInput} from "mui-tel-input";

function PhoneNumberForm() {
    const [optIn, setOptIn] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState('');

    const handleTelChange = (value) => {
        setPhoneNumber(value);
    }
    const handleSubmit = (event) => {
        event.preventDefault();
        if (optIn) {
            toast.success('You have opted in to receive messages on ' + phoneNumber);
        } else {
            toast.error('If you want to receive messages to ' + phoneNumber + ', you must opt in');
        }
    };
    return (
        <Box component="form" onSubmit={handleSubmit} noValidate autoComplete="off"
             sx={{display: 'flex', flexDirection: 'column', gap: 2, padding: 2}}>
            <MuiTelInput value={phoneNumber} onChange={handleTelChange} />
            <FormControlLabel
                sx={{color: 'black'}}
                control={
                    <Checkbox
                        checked={optIn}
                        onChange={e => setOptIn(e.target.checked)}
                    />
                }
                label="Opt-In to receive messages"
            />
            <Button type="submit" variant="contained">Submit</Button>

        </Box>
    );
}

export default PhoneNumberForm;
